import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { Box, Progress, Center, Heading, Text, Image } from "@chakra-ui/react";
import { Highlight } from "@chakra-ui/react";

const Splash = () => {
  let navigate = useNavigate();
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/home");
    }, 8000);

    const progressTimer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(progressTimer);
        }
        return Math.min(oldProgress + Math.ceil((100 - oldProgress) / 10), 100);
      });
    }, 100);

    return () => {
      clearTimeout(timer);
      clearInterval(progressTimer);
    };
  }, [navigate]);

  const containerVariants = {
    initial: { opacity: 0 },
    animate: { opacity: 1, transition: { duration: 1, delay: 0.5 } },
    exit: { opacity: 0, transition: { duration: 0.5 } },
  };

  const rotateAnimation = {
    rotate: [0, 360],
    transition: {
      repeat: Infinity,
      ease: "linear",
      duration: 15,
    },
  };

  const backgroundAnimation = {
    animate: {
      background: [
        "linear-gradient(135deg, #0f2027 0%, #203a43 50%, #2c5364 100%)",
        "linear-gradient(135deg, #232526 0%, #414345 100%)",
        "linear-gradient(135deg, #304352 0%, #d7d2cc 100%)",
        "linear-gradient(135deg, #1f1c2c 0%, #928dab 100%)",
      ],
      transition: {
        repeat: Infinity,
        repeatType: "reverse",
        duration: 10,
        ease: "linear",
      },
    },
  };

  return (
    <motion.div
      variants={backgroundAnimation}
      animate="animate"
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <motion.div
        variants={containerVariants}
        initial="initial"
        animate="animate"
        exit="exit"
        style={{
          width: "100%",
          maxWidth: "600px",
          margin: "auto",
          textAlign: "center",
          position: "relative",
        }}
      >
        <Center h="100vh">
          <Box width="100%">
            <Box
              position="relative"
              display="inline-flex"
              justifyContent="center"
              alignItems="center"
              width="full"
              height="350px"
            >
              <motion.div animate={rotateAnimation}>
                <Image
                  src="/circlepixel.png"
                  alt="large logo"
                  boxSize="350px"
                  m="auto"
                />
              </motion.div>
              <Image
                src="/mac.png"
                alt="small logo"
                boxSize="150px"
                position="absolute"
              />
            </Box>
            <Progress
  colorScheme="blue"
  size="lg"
  value={progress}
  hasStripe
  isAnimated
  borderRadius="md"
  boxShadow="lg"
  marginTop="6"
/>
<Heading
  lineHeight="tall"
  color="gray.200" // Lighter font color for the heading
  fontFamily="'Roboto', sans-serif"
  fontSize="3xl"
  fontWeight="extrabold"
  mt="6"
>
  Welcome to My Digital Space! 👋
</Heading>
<Text color="gray.200" fontSize="2xl" mb="2"> 
  Hello, I'm Thomas. Glad you could make it. 🙂
</Text>
<Text color = "gray.200" fontSize="2xl" fontWeight="semibold">
  <Highlight
    query={["Software Engineer", "Create", "Innovation", "Passionate", "Learning", "Build"]}
    styles={{
      px: "2",
      py: "1",
      paddingY: "0",
      rounded: "full",
      bg: "blue.500",
      color: "gray.200", 
      fontWeight: "bold",
    }}
  >
    I am a Software Engineer whose passionate about continuous learning and Innovation. I love to create and build things that make a difference.
  </Highlight>
</Text>
          </Box>
        </Center>
      </motion.div>
    </motion.div>
  );
};

export default Splash;
